import React, {useEffect, useRef} from "react"
import useGlobalHook from 'use-global-hook';

const useGlobal = useGlobalHook(React, {
	windowSize: {
		width: window.innerWidth,
		height: window.innerHeight,
	}
}, {
	setWindowSize: (store, windowSize) => {
		store.setState({windowSize})
	}
});

let isListening = false

export default function useWindowSize() {
	const isClient = typeof window === 'object';
	const [globalState, globalActions] = useGlobal();

	function getSize() {
		return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined
		};
	}

	const handleResize = useRef(function handleResize() {
		globalActions.setWindowSize(getSize());
	})

	useEffect(() => {
		if (!isClient) {
			return false;
		}
		if (isListening === true) return;
		window.addEventListener('resize', handleResize.current);
		isListening = true
		return () => window.removeEventListener('resize', handleResize.current);
	}, []); // Empty array ensures that effect is only run on mount and unmount

	return globalState.windowSize;
}
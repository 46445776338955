import jsonFetcher from "../../lib/jsonFetcher/jsonFetcher"
import { useState } from "react"

export default function useForgotPassword() {
	const [isLoading, setIsLoading] = useState()
	const [error, setError] = useState()
	const [success, setSuccess] = useState()

	function forgotPassword(email) {
		if (!isLoading) {
			setError("")
			setIsLoading(true)
			jsonFetcher(`/api/auth/forgotPassword?email=${email}`)
				.then(data => {
					setSuccess(data)
				})
				.catch(e => setError(e.message))
				.then(() => setIsLoading(false))
		}

	}

	return {
		forgotPassword,
		forgotPasswordSuccess: success,
		forgotPasswordError: error
	}
}
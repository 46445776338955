import { loginActions } from "./actions";

export default function AuthReducer(state = {}, action) {
	switch (action.type) {

	case loginActions.check.success:
	case loginActions.login.success:
		return {
			...state,
			user: action.payload,
			isLoggedIn: true,
			isLoading: false,
			errors: null,
			extra: null
		}

	case loginActions.check.fetch:
	case loginActions.login.fetch:
		return {
			...state,
			isLoggedIn: false,
			isLoading: true,
			errors: null,
			extra: null
		}
	case loginActions.check.fail:
	case loginActions.login.fail:
		return {
			...state,
			isLoggedIn: false,
			isLoading: false,
			...(loginActions.login.fail === action.type && {
				errors: action.errors || action.message,
				extra: action.extra
			})
		}

	case loginActions.logout.success:
		return {
			...state,
			isLoggedIn: false,
			isLoading: false,
			errors: null,
			extra: null
		}

	default:
		return state;
	}

}
export class UnAuthorizedError extends Error {
	constructor(...params) {
		super(...params);
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, UnAuthorizedError);
		}
		this.name = 'UnAuthorizedError';
		this.date = new Date();
	}

}

export function futch(url, opts = {}, onProgress) {
	return new Promise((res, rej) => {
		var xhr = new XMLHttpRequest();
		xhr.open(opts.method || 'get', url);
		for (var k in opts.headers || {})
			xhr.setRequestHeader(k, opts.headers[k]);
		xhr.onload = e => {
			if(e.target.status !== 200){
				return rej(JSON.parse(e.target.responseText));
			}
			res(JSON.parse(e.target.responseText))
		};
		xhr.onerror = rej;
		if (xhr.upload && onProgress)
			xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
		xhr.send(opts.body);
	});
}

export default function jsonFetcher(url, params = {}) {
	return fetch(url, {
		...params,
		...((params.method && params.method.toLowerCase() === "post")
			&& { headers: { ...params.headers, "Content-Type": "application/json" } }),
		...(params.body && { body: JSON.stringify(params.body) })
	}).then(r => {
		return r.json()
			.then(data => {
				if (!r.ok) {
					throw data
				}
				return data;
			})
	})
}
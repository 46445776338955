import React from 'react';
import Flex from "../../../common/components/Flex/Flex";
import AbstractMenu from "./AbstractMenu";
import FadingTitle from "../../../common/components/FadingTitle/FadingTitle";

function MenuItem({active, index, length, ...props}) {
	return <FadingTitle {...props} size={12}
						active={active}
						unActiveOpacity={.7}
						delay={(length - index) / 15}
						uppercase duration={1} style={{
							fontWeight:600,
		margin: "0 15px",
	}}/>
}

function HorizontalMenu() {
	return (
		<Flex row>

			<AbstractMenu Component={MenuItem}/>
		</Flex>
	);
}

export default HorizontalMenu;
import React from "react";
import {ColWrapper, RowWrapper} from "../../components/Wrapper/Wrapper";
import Title from "../../../common/components/Title/Title";
import {FormattedMessage} from "react-intl";
import Text from "../../../common/components/Text/Text";
import withWrapper from "../../components/Wrapper/withWrapper";

function OurStoryPage() {

	return (
		<RowWrapper>
			<ColWrapper xs={24} sm={24} md={{span: 6, offset: 5}}>
				<img src="/assets/photos/portrait.jpg" width="100%"/>
			</ColWrapper>
			<ColWrapper xs={24} sm={24} md={{span: 11, offset: 1}}>
				<Title><FormattedMessage id="ourStory"/></Title>
				<Text>
					<FormattedMessage id="ourStoryDescription"/>
				</Text>
			</ColWrapper>


		</RowWrapper>
	)
}

export default withWrapper(OurStoryPage);

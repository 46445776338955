import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import CoverItemOverlay from "../../components/CoverItem/CoverItemOverlay";
import useUserCollections from "../../hooks/useUserCollections";

function Collections({match, collections}) {



	return collections
		&& collections.map((collection, index) => (
			<Link key={index} to={match.path + "/" + collection._id}>
				<CoverItemOverlay
					style={{
						height: 300,
						cursor: 'pointer',
					}}

					photo={collection.coverPhoto}
					title={collection.title["en"]}/>
			</Link>
		));
}

export default Collections;

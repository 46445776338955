import React from 'react';
import LogoWithHomeLink from "./LogoWithHomeLink";
import ResponsiveUtils from "../../../common/components/Responsivness/Responsivness";

function LogoForNavBar() {
	return (
		<LogoWithHomeLink width={ResponsiveUtils.choose({mobile: 50, other: 80})}/>
	);
}

export default LogoForNavBar;
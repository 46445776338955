import React from 'react';
import EmailSubscription from '../../components/EmailSubscription/EmailSubscription';
import useWindowSize from '../../../common/hooks/useWindowSize/useWindowSize';
import {FormattedMessage} from 'react-intl';
import withWrapper from "../../components/Wrapper/withWrapper";


const Background = (props) => {
	return <div {...props} style={{
		width: "100%",
		maxWidth:1000,
		height: "100%",
		background: `url(/assets/photos/skirt.jpg) center center /cover`,
		...props.style
	}}/>
}

function HomePage({Component}) {
	const {height} = useWindowSize()
	const backgroundHeight = height - 110;
	return (
		<>
			<div style={{
				position: "relative",
				height: backgroundHeight,
			}}>
				<Component
					Background={Background}
					title={<FormattedMessage id={"coverPhrase"}/>}
				/>
			</div>

			<EmailSubscription/>
		</>
	);
}

export default withWrapper(HomePage);

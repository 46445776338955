import React from "react";
import Form from "../../../common/components/Form/Form"
import InputBox from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import {FormattedMessage, injectIntl} from "react-intl";
import useRegister from "./useRegister";
import Flex from "../../../common/components/Flex/Flex";
import FormError from "../../components/FormError/FormError";
import {Select} from "antd";
import WrapperWithLogo from "../../components/WrapperWithLogo/WrapperWithLogo";
import SelectBox from "../../../common/components/SelectBox/SelectBox";
import Text from "../../../common/components/Text/Text";

function RegisterPage({
						  intl,
						  history
					  }) {

	const [register, isLoading, errors] = useRegister()

	return (
		<WrapperWithLogo>
			<Form onSubmit={register} style={{maxWidth: 600}}>
				<InputBox
					name="firstName"
					required
					placeholder={intl.formatMessage({id: "yourFirstName"})}/>
				<InputBox
					name="lastName"
					required
					placeholder={intl.formatMessage({id: "yourLastName"})}/>
				<InputBox
					name="email"
					type="email"
					required
					placeholder={intl.formatMessage({id: "email"})}/>
				<InputBox
					name="password"
					type="password"
					required
					placeholder={intl.formatMessage({id: "password"})}/>
				<InputBox
					name="size"
					required
					defaultValue=""
					type="number"
					placeholder={intl.formatMessage({id: "yourSize"})}
					style={{
						display: "block"
					}}/>
				<label style={{
					margin: "0 auto"
				}}>
					<Text><FormattedMessage id="yourGender"/></Text>
					<InputBox
						name="gender"
						required
						Component={SelectBox}
						defaultValue=""
						style={{
							display: "block"
						}}>
						{
							["male", "female", "other"].map((gender, index) => (
								<Select.Option key={index} value={gender}>
									<FormattedMessage id={gender}/>
								</Select.Option>
							))
						}
					</InputBox>
				</label>
				<FormError errors={errors}/>
				<Flex row justifyContent="space-between">
					<Button
						onClick={() => history.goBack()}
						icon="left"><FormattedMessage id="home"/></Button>
					<Button
						loading={isLoading}
						htmlType="submit" type="primary"><FormattedMessage id="register"/></Button>
				</Flex>
			</Form>
		</WrapperWithLogo>
	)
}

export default injectIntl(RegisterPage); 
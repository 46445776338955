import React from 'react';
import Title from "../../../common/components/Title/Title";

function ThumbnailItem({
						   title,
						   subTitle,
						   picture
					   }) {
	return (
		<div style={{
			width: "100%",
			display: 'inline-block'
		}}>

			<div style={{
				background: `#EEE url(${picture}) no-repeat center center`,
				backgroundSize: 'cover'
			}}>
				<div style={{
					width:0,
					paddingBottom: "100%"
				}}/>
			</div>

			<Title uppercase size={16}>{title}</Title>
			{subTitle && <Title sub>{subTitle}</Title>}
		</div>
	);
}

export default ThumbnailItem;

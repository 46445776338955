import { useState } from "react";
import jsonFetcher from "../lib/jsonFetcher/jsonFetcher";

export default function useSubscribe() {
	const [isLoading, setIsLoading] = useState()
	const [error, setError] = useState()
	const [success, setSuccess] = useState()

	function subscribe(data) {

		setError(null)

		if (!isLoading) {
			setIsLoading(true)
			jsonFetcher("/api/subscription/subscribe", {
				method: "post",
				body: data
			})
				.then(data => {
					setSuccess(data)
				})
				.catch(e => setError(e.errors || { general: { message: e.message } }))
				.then(() => {
					setIsLoading(false)
				})
		}

	}

	return [
		subscribe,
		success,
		error,
		isLoading
	]

}
import styled from "styled-components";
import Title from "../Title/Title";


const FadingTitle = styled(Title)`
	@keyframes anim-fade-in{
		0%{opacity:0}
	}
	opacity: ${({active, unActiveOpacity=.7}) => active ? 1 : unActiveOpacity};
	animation: anim-fade-in  ${({duration=.6})=>duration}s  ${({delay}) => delay}s both;
`


export default FadingTitle;
import React from 'react'
import Wrapper from '../../components/Wrapper/Wrapper'
import Flex from '../../../common/components/Flex/Flex'
import Form from '../../../common/components/Form/Form'
import useResetPassword from './useResetPassword'
import Logo from '../../components/Logo/Logo'
import { FormattedMessage, injectIntl } from 'react-intl'
import Text from '../../../common/components/Text/Text'
import FormError from '../../components/FormError/FormError'
import InputBox from '../../components/Input/Input'
import { Link } from 'react-router-dom'
import Button from '../../components/Button/Button'

function ResetPassword({ match, intl }) {
	const { token } = match.params

	const { resetPassword,
		passwordResetIsLoading,
		passwordResetSuccess,
		passwordResetError } = useResetPassword();

	return <Wrapper>
		<Flex style={{ minHeight: "100%" }}>
			{
				passwordResetSuccess ?
					(
						<div>
							<Text>
								<FormattedMessage id="yourPasswordHasBeenResetYouMayLoginNow" />.
							</Text>
							<Link to="/">
								<Button icon="left"><FormattedMessage id="home" /></Button>
							</Link>
						</div>
					) : (

						<Form
							disabled={passwordResetIsLoading}
							style={{
								maxWidth: 600
							}}
							onSubmit={({ newPassword, newPassword2 }) => resetPassword(token, newPassword, newPassword2)}>
							<Logo width={180}
								style={{
									margin: "auto"
								}} />
							<Text
								grayed small
								style={{
									margin: 15
								}}>
								<FormattedMessage id="pleaseFillInYourNewPasswordBelowToResetYourAccountPassword" />
							</Text>
							<FormError errors={passwordResetError} />
							<InputBox
								required
								placeholder={intl.formatMessage({ id: "newPassword" })}
								type="password"
								name="newPassword" />
							<InputBox
								required
								placeholder={intl.formatMessage({ id: "confirmNewPassword" })}
								type="password"
								name="newPassword2" />
							<Flex row justifyContent="space-between">
								<Link to="/">
									<Button icon="left"><FormattedMessage id="home" /></Button>
								</Link>
								<Button
									loading={passwordResetIsLoading}
									htmlType="submit" type="primary"><FormattedMessage id="login" /></Button>
							</Flex>
						</Form>
					)
			}
		</Flex >
	</Wrapper>

}

export default injectIntl(ResetPassword)

import { useDispatch, useSelector } from "react-redux";
import { login, checkLogin } from "./actions";

export default function useLogin() {
	const isLoading = useSelector(({ auth }) => auth.isLoading)
	const error = useSelector(({ auth }) => auth.errors)
	const extra = useSelector(({ auth }) => auth.extra)

	const dispatch = useDispatch()



	/*
		function login(email, password) {
			if (!isLoading) {
				setError(null)
				setIsLoading(true)
				return jsonFetcher("/api/auth/login", {
					method: "post",
					body: {
						email,
						password
					}
				}).then(() => {
					setIsLoading(false)
				}).catch(e => {
					setError(e.errors || e.message)
					setIsLoading(false)
				})
			}
	
		}*/

	return {
		login: (email, password) => {
			dispatch(login({ email, password }))
		},
		isLoading,
		loginError: error,
		checkLogin: () => {
			dispatch(checkLogin())
		},
		extraLoginHelp: extra
	}
}
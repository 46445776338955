import React, { useEffect, Suspense } from 'react';
import { Switch, Route } from "react-router-dom"
import { connect, useSelector } from 'react-redux';
import TopProgress from '../common/components/TopProgress/TopProgress';
import { useDebounce } from 'use-debounce';
import DisconnectionAlert from '../common/components/DisconnectionAlert/DisconnectionAlert';
import WelcomePage from './WelcomePage/WelcomePage';
import { injectIntl } from 'react-intl';
import { message } from 'antd';
import Footer from './components/Footer/Footer';
//import AdminRoutes from './Admin/AdminRoutes';

import HomeRoutes from './Home/HomeRoutes';
import ResetPassword from './Auth/ResetPassword/ResetPassword';
import RegisterPage from './Auth/RegisterPage/RegisterPage';
import LoginPage from "./Auth/LoginPage/LoginPage";

const AdminRoutes = React.lazy(() => import("./Admin/AdminRoutes"));


function Routes({ isLoading, intl }) {
	const [debouncedIsLoading] = useDebounce(isLoading, 500)
	const hash = window.location.hash.replace("#", "")
	useEffect(() => {
		hash && message.info(intl.formatMessage({ id: hash }), 3)
	}, [hash])

	const topMessage = useSelector(({ main }) => main.topMessage) || {}

	useEffect(() => {
		topMessage.message
			&& topMessage.status
			&& message[topMessage.status](intl.formatMessage({ id: topMessage.message }), 3)
	}, [topMessage.message, topMessage.status])

	return (
		<>
			<DisconnectionAlert>
				<TopProgress isLoading={debouncedIsLoading} />
				<Suspense fallback={<div>loading...</div>}>
					<Switch>
						<Route path="/admin" component={AdminRoutes} />
						<Route path="/home" component={HomeRoutes} />
						<Route path="/register" component={RegisterPage} />
						<Route path="/login" component={LoginPage} />
						<Route path="/passwordReset/:token" component={ResetPassword} />
						<Route path="/" exact component={WelcomePage} />

					</Switch>
				</Suspense>
				<Footer />
			</DisconnectionAlert>
		</>
	);
}

export default injectIntl(connect(
	({ main }) => ({ isLoading: main.isLoading, topMessage: main.topMessage })
)(Routes));

import { useSelector, useDispatch } from "react-redux";
import { changeLanguage } from "../../../specific/Store/common/actions";

const LOCALE =
    (navigator.languages && navigator.languages[0])
    || navigator.language
    || navigator.userLanguage
    || 'en-US';

export default function useLanguage() {
	const locale = useSelector(({ main }) => main.language) || LOCALE
	const dispatch = useDispatch()

	return [
		locale,
		locale.split("-")[0],
		(locale) => {
			dispatch(changeLanguage(locale))
		}
	]
}
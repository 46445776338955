import React from 'react'
import NavBar from './NavBar';
import Flex from '../../../common/components/Flex/Flex';
import UserButton from './UserButton';
import useWindowSize from '../../../common/hooks/useWindowSize/useWindowSize';
import ResponsiveUtils from '../../../common/components/Responsivness/Responsivness';
import {useDispatch, useSelector} from 'react-redux';
import {Icon} from "antd";
import ShoppingCart from '../ShoppingCart/ShoppingCart';
import MobileOnly from "../../../common/components/Responsivness/MobileOnly";
import {toggleMenu} from "../../Store/common/actions";
import LogoForNavBar from "../Logo/LogoForNavBar";
import HorizontalMenu from "../Menu/HorizontalMenu";


function UserBar() {
	useWindowSize()
	const isLoggedIn = useSelector(({auth}) => auth.isLoggedIn)
	const isMenuOpen = useSelector(({main}) => main.isMenuOpen)
	const dispatch = useDispatch();

	function toggleMenuHandle() {
		dispatch(toggleMenu())
	}

	//const [blurDisabled, setBlurDisabled] = useState()

	return (
		<NavBar style={{
			backgroundColor: "rgba(255,255,255,.85)",
			WebkitBackdropFilter: !isMenuOpen && "saturate(200%) blur(20px)",
			backdropFilter: !isMenuOpen && "saturate(200%) blur(20px)",
			height: 80,
			padding: "0 20px",
		}}>
			<Flex
				style={{minHeight: "100%"}}
				justifyContent="space-between" row>
				<Flex row
					  style={{
						  height: 60,
						  width: ResponsiveUtils.choose({mobile: "100%", other: undefined})
					  }}
					  justifyContent="space-between">
					<MobileOnly>
						<Icon type={isMenuOpen ? "close" : "menu"} onClick={toggleMenuHandle}/>
					</MobileOnly>
					<LogoForNavBar/>
					<MobileOnly>
						<ShoppingCart/>
					</MobileOnly>
				</Flex>
				{
					ResponsiveUtils.choose({mobile: false, other: true})
					&& <HorizontalMenu/>
				}
				<div>
					<UserButton/>
					{
						false && <ShoppingCart style={{margin: "0 15px"}}/> // Only show up if there is a product
					}
				</div>
				{isLoggedIn && <UserButton/>}
			</Flex>
		</NavBar>
	)
}

export default UserBar

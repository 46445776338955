import React from "react";
import Icons from "../Icons/Icons";


export default function ShoppingCart({ style }) {
	return (
		<Icons.ShoppingCart style={{
			fontSize: 25,

			...style
		}} />
	)
}
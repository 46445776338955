import React, {useEffect} from 'react';
import Title from "../../../common/components/Title/Title";
import {FormattedMessage} from "react-intl"
import Products from "../../components/Products/Products";
import {Route, Switch} from "react-router-dom";
import Collections from "./Collections";
import useUserCollections from "../../hooks/useUserCollections";
import Wrapper from "../../components/Wrapper/Wrapper";

function ShopNow({match}) {
	const {collections, fetchCollections} = useUserCollections();

	useEffect(() => {
		fetchCollections();
	}, [])


	return (
		<>
			<div
				style={{
					textAlign: 'center',
				}}>
				<Title style={{fontWeight: 100}}><FormattedMessage id="shopNow"/></Title>
				<Title sub>
					<FormattedMessage id="chooseYourOwnStyle"/>
				</Title>
			</div>
			<Switch>
				{
					collections
					&& collections.map((collection, index) => (
						<Route key={index} path={`${match.path}/${collection._id}`} render={
							props => <Products {...props} collection={collection}/>
						}/>
					))
				}
				<Wrapper>
					<Route
						render={props => <Collections {...props} collections={collections}/>}
					/>
				</Wrapper>
			</Switch>
		</>
	);
}

export default ShopNow;

import React from 'react'

function Oval({ style, ...props }) {
	return (
		<div {...props} style={{
			borderRadius: '9999px',
			border: 'solid 1px #DDD',
			marginBottom: 15,
			...style,
		}} />
	)
}

export default Oval

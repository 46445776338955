import React from 'react'
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import Text from '../../../common/components/Text/Text';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'antd';



function Footer() {
	return (
		<Row
			style={{ padding: "10px 20px" }} >
			<Col sm={8}>

			</Col>
			<Col sm={8} style={{ textAlign: "center" }}>
				<Text small grayed>© <FormattedMessage id="allRightsAreReserved" /></Text>
			</Col>

			<Col sm={8} style={{ textAlign: "right" }}>
				<LanguageSelector />
			</Col>
		</Row>
	)
}

export default Footer

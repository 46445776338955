import React from 'react'
import { Select } from 'antd';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled){
        color:white;
    }
`

function SelectBox({ style, ...props }) {
    return (
        <>
            <GlobalStyle />
            <Select {...props}
                style={{
                    width: 100,
                    ...style
                }} />

        </>
    )
}

export default SelectBox

import React, {useEffect} from 'react';
import {ColWrapper, RowWrapper} from "../Wrapper/Wrapper";
import ThumbnailItem from "../ThumbnailItem/ThumbnailItem";
import useUserProducts from "../../hooks/useUserProducts";
import {FormattedMessage} from "react-intl";
import Title from "../../../common/components/Title/Title";

function Products({collection,...props}) {
	const {productsLoading, products, fetchProducts} = useUserProducts();

	useEffect(() => {
		fetchProducts({
			"productCollection._id":collection._id
		});
	}, [])
	return (
		<RowWrapper style={{
			justifyContent:"space-around"
		}}>
			<Title style={{
				fontWeight: 100,
				textAlign: 'center',
			}}>{collection.title["en"]}</Title>
			<Title sub>
				{collection.description["en"]}
			</Title>
			{
				products
				&& products.map((product, index) => (
					<ColWrapper
						xs={{span:22,offset:0}}
						sm={{span:22,offset:0}}
						md={{span: 11, offset:0}} key={index}>
						<ThumbnailItem
							title={product.title["en"]} picture={product.pictures[0]}/>
					</ColWrapper>
				))
			}

		</RowWrapper>
	);
}

export default Products;

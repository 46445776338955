import React from "react"
import Flex from "../../../common/components/Flex/Flex";
import Form from "../../../common/components/Form/Form";
import InputBox from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Logo from "../../components/Logo/Logo";
import Wrapper from "../../components/Wrapper/Wrapper";
import { injectIntl, FormattedMessage } from "react-intl";
import Text from "../../../common/components/Text/Text";
import { Link, Switch, Route } from "react-router-dom";
import useLogin from "../useLogin";
import FormError from "../../components/FormError/FormError";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import useConfirmationEmail from "./useConfirmationEmail";

function LoginPage({
	intl,
	match
}) {
	const { login, loginError, isLoading, extraLoginHelp } = useLogin()
	const { resendConfirmationEmail, isSending, isSent } = useConfirmationEmail()

	return (
		<Wrapper>
			<Flex style={{ minHeight: "100%" }}>
				<div style={{ maxWidth: 600 }}>
					<Switch>
						<Route path={match.path + "/forgotPassword"} component={ForgotPassword} />
						<Form
							disabled={isLoading}
							onSubmit={({ email, password }) => login(email, password)}>
							<Logo width={180}
								style={{
									margin: "auto"
								}} />
							<Text
								grayed small
								style={{
									margin: 15
								}}>
								<FormattedMessage id="welcomeBackPleaseSigninBelow" />
							</Text>
							<FormError errors={loginError} extra={
								(loginError === "accountIsNotConfirmedPleaseConfirmYourAccount" &&
									<Button
										disabled={isSending || isSent}
										loading={isSending}
										size="small"
										onClick={() => resendConfirmationEmail(extraLoginHelp.token)}>
										{
											(isSending || isSent)
												? <FormattedMessage id="emailWasSent" />
												: <FormattedMessage id="resendAnEmail" />
										}
									</Button>)
							} />
							<InputBox
								required
								placeholder={intl.formatMessage({ id: "email" })}
								name="email" />
							<InputBox
								required
								placeholder={intl.formatMessage({ id: "password" })}
								type="password"
								name="password" />
							<Text small grayed style={{ textAlign: "center" }}>
								<Link to={`${match.path}/forgotPassword`}>
									<FormattedMessage id="forgotYourPassword" />?
								</Link>
							</Text>
							<Flex row justifyContent="space-between">
								<Link to="/">
									<Button icon="left"><FormattedMessage id="home" /></Button>
								</Link>
								<Button
									loading={isLoading}
									htmlType="submit" type="primary"><FormattedMessage id="login" /></Button>
							</Flex>
						</Form>
					</Switch>
				</div>
			</Flex>
		</Wrapper>
	)
}

export default injectIntl(LoginPage)
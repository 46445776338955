import { useState } from "react";
import jsonFetcher from "../lib/jsonFetcher/jsonFetcher";


export default function useProducts() {
	const [isLoading, setIsLoading] = useState()
	const [products, setProducts] = useState();
	const [statistics, setStatistics] = useState();

	function fetchProducts(searchObject = {}, page = 0) {
		if (!isLoading) {
			setIsLoading(true)
			jsonFetcher(`/api/product/products?page=${page}`, {
				method: "post",
				body: {
					filter:searchObject
				}
			})
				.then(({ documents, statistics }) => {
					setProducts(documents)
					setStatistics(statistics)
				})
				.catch()
				.then(() => {
					setIsLoading(false)
				})
		}
	}


	return {
		productsLoading:isLoading,
		productStatistics:statistics,
		products,
		fetchProducts
	}
}
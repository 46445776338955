import React from 'react'
import { Row, Col } from 'antd';

export function ColWrapper({ fluid, ...props }) {
	return <Col
		xs={(fluid ? 24 : { span: 22, offset: 1 })}
		sm={fluid ? 24 : { span: 20, offset: 2 }}
		{...props} />
}

export function RowWrapper({ middle, style, ...props }) {
	return <Row type="flex" align={middle && "middle"}
		{...props}
		style={{ minHeight: '100vh', paddingTop: 15, paddingBottom: 15, ...style }}
	/>
}

function Wrapper({ rowStyle, ...props }) {
	return (
		<RowWrapper style={{
			...rowStyle
		}}>
			<ColWrapper {...props} />
		</RowWrapper>
	)
}

export default Wrapper

import jsonFetcher from "../../lib/jsonFetcher/jsonFetcher"
import { useState } from "react"

export default function useConfirmationEmail() {
	const [isSending, setIsSending] = useState()
	const [isSent, setIsSent] = useState()
	function resendConfirmationEmail(token) {
		if (!isSending) {
			setIsSending(true)
			jsonFetcher(`/api/auth/resendConfirmationEmail?token=${token}`)
				.then(() => {
					setIsSent(true)
				})
				.catch(console.log)
				.then(() => {
					setIsSending(false)
				})
		}

	}

	return {
		resendConfirmationEmail,
		isSent,
		isSending
	}
}
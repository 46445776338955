import jsonFetcherCommon from "../../../common/lib/jsonFetcher/jsonFetcher";

function jsonFetcher(url, params = {}) {
	return jsonFetcherCommon(url, params)
		.catch(e => {
			throw e
		})
}



export default jsonFetcher
import React from 'react'
import styled from 'styled-components';
import { Affix } from 'antd';

const Div = styled.div`
    width:100%;
    height:50px;
`

function NavBar({  children, style = {} }) {
	const height = style.height || 60;
	return (
		<Affix style={{
			width: "100%",
		}}>
			<Div style={{
				height,
				...style
			}}>
				{children}
			</Div>
		</Affix>
	)
}

export default NavBar

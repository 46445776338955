import { useState } from "react"
import jsonFetcher from "../../lib/jsonFetcher/jsonFetcher"

export default function () {
	const [isLoading, setIsLoading] = useState()
	const [error, setError] = useState()
	const [success, setSuccess] = useState()

	return {
		resetPassword: (token, newPassword, newPassword2) => {
			if (newPassword !== newPassword2) {
				setError("yourPasswordDoesntMatchWithTheConfirmation")
				return false
			}
			if (!isLoading) {
				setError("")
				setIsLoading(true)
				jsonFetcher(`/api/auth/passwordReset?token=${token}`, {
					method: "post",
					body: {
						newPassword
					}
				})
					.then(data => {
						setSuccess(data)
					})
					.catch(e => setError(e.message))
					.then(() => setIsLoading(false))
			}
		},
		passwordResetIsLoading: isLoading,
		passwordResetError: error,
		passwordResetSuccess: success
	}
}
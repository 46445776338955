import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'

import logger from "redux-logger";
import allReducers from './allReducers';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
	allReducers,
	applyMiddleware(sagaMiddleware, logger)
)


sagaMiddleware.run(rootSaga)


export default store
import useFetcher from "../../hooks/useFetcher"


export default function useRegister() {
	const [fetch, loading, error, setError] = useFetcher();
	function register(user) {
		fetch("/api/auth/register",{
			method:"post",
			body:user
		}).catch(e=>setError(e.errors));
	}
	return [
		register,
		loading,
		error
	]
}
import React from 'react';
import AbstractMenu from "./AbstractMenu";
import Title from "../../../common/components/Title/Title";
import styled from "styled-components";
import Flex from "../../../common/components/Flex/Flex";
import {useDispatch} from "react-redux";
import {toggleMenu} from "../../Store/common/actions";
import FadingTitle from "../../../common/components/FadingTitle/FadingTitle";


const MenuItem = ({children, index, active}) => (
	<FadingTitle uppercase size={30} active={active} delay={index/15}>
		{children}
	</FadingTitle>
)

function MenuFullPage() {
	const dispatch = useDispatch();
	function closeMenu(){
		dispatch(toggleMenu(false));
	}
	return (
		<Flex fullHeight onClick={closeMenu}>
			<AbstractMenu Component={MenuItem}/>
		</Flex>
	);
}

export default MenuFullPage;
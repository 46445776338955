import React from "react"
import Flex from "../../../common/components/Flex/Flex";
import Form from "../../../common/components/Form/Form";
import InputBox from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import Logo from "../../components/Logo/Logo";
import Wrapper from "../../components/Wrapper/Wrapper";
import { injectIntl, FormattedMessage } from "react-intl";
import Text from "../../../common/components/Text/Text";
import { Link } from "react-router-dom";
import FormError from "../../components/FormError/FormError";
import useForgotPassword from "./useForgotPassword";

function ForgotPassword({
	intl,
	history
}) {
	const { forgotPassword, isLoading, forgotPasswordError, forgotPasswordSuccess } = useForgotPassword()


	return (
		<Wrapper>
			<Flex style={{ minHeight: "100%" }}>
				{forgotPasswordSuccess ? (
					<div>
						<Text>
							<FormattedMessage id="youWillReceiveAnEmailToResetYourPassword" />
						</Text>
						<Link to="/">
							<Button icon="left"><FormattedMessage id="home" /></Button>
						</Link>
					</div>

				) : (
					<Form
						disabled={isLoading}
						onSubmit={({ email }) => forgotPassword(email)}>
						<Logo width={180}
							style={{
								margin: "auto"
							}} />
						<Text
							grayed small
							style={{
								margin: 15
							}}>
							<FormattedMessage id="enterYourEmailUsedToCreateYourAccount" />
						</Text>
						<FormError errors={forgotPasswordError} />
						<InputBox
							required
							placeholder={intl.formatMessage({ id: "email" })}
							name="email" />

						<Flex row justifyContent="space-between">
							<Button icon="left" onClick={() => history.goBack()}><FormattedMessage id="back" /></Button>
							<Button
								loading={isLoading}
								htmlType="submit" type="primary"><FormattedMessage id="sendMeAnEmail" /></Button>
						</Flex>
					</Form>
				)}

			</Flex>
		</Wrapper>
	)
}

export default injectIntl(ForgotPassword)
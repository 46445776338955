import React from 'react';
import HomePage from "./HomePage";
import ResponsiveUtils from "../../../common/components/Responsivness/Responsivness";
import {FormattedMessage} from "react-intl";
import Flex from "../../../common/components/Flex/Flex";
import useWindowSize from "../../../common/hooks/useWindowSize/useWindowSize";
import Title from "../../../common/components/Title/Title";

function HomePageTitle({isMobile}) {
	return <Flex alignItems="left" fullHeight>
		<Title style={{
			fontWeight: 100,
			width: isMobile?"100%":"35vw",
			textAlign:"left",
			//textAlignLast:"justify",
			lineHeight:1,
			paddingLeft:10,
			paddingRight:10,
			color: isMobile ? "white" : "black",
			textTransform: "initial",
		}} size={ResponsiveUtils.choose({mobile: "15vw", tablet: "10vh", other: "15vh"})}>
			<FormattedMessage id={"coverPhrase"}/>
		</Title>
	</Flex>
}

function HomePageOverlay() {
	useWindowSize()
	const isMobile = ResponsiveUtils.choose({mobile: true, other: false})
	return (
		<HomePage
			Component={({Background}) => (
				<>
					<Background style={{
						position: "absolute",
						right:0, // So it can go to the right if we have a lot of space
						zIndex: -1,
						marginLeft: isMobile ? "auto" : "10%",
					}}/>
					<HomePageTitle isMobile={isMobile}/>
				</>
			)}
		/>
	);
}

export default HomePageOverlay;

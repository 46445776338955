import React from 'react'
import styled from 'styled-components';

const Div = styled.div`
    position:fixed;
    top: 0; left: 0;
    z-index:999;
    width: 100%;height: 3px;
    background-color:${({ theme }) => theme.colors.primary};
    display:${({ isLoading }) => isLoading ? "block" : "none"};
    animation: top-progress-bar-anim .9s linear infinite;
    transform-origin:top left;
    
    @keyframes top-progress-bar-anim{
        0%{
            transform:scaleX(0) translateX(0);
        }
        50%{
            transform:  scaleX(1.4) translateX(-30%); 
        }
        100%{
            transform:  scaleX(1) translateX(100%);
        }
    }
`

function TopProgress({
	isLoading = false
}) {
	return (
		<Div isLoading={isLoading} />
	)
}

export default TopProgress

import React from 'react';
import {Link} from "react-router-dom";
import Logo from "./Logo";
import useWindowSize from "../../../common/hooks/useWindowSize/useWindowSize";

function LogoWithHomeLink(props) {
	useWindowSize();
	return (
		<Link to="/">
			<Logo {...props}/>
		</Link>
	);
}

export default LogoWithHomeLink;
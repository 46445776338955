import React from "react"
import { Alert as AntdAlert } from "antd"

function Alert({ style, ...props }) {
	return (<AntdAlert {...props} style={{
		marginBottom: 15,
		textAlign: "left",
		...style,
	}} />)
}
export default Alert;
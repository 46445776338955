import React from "react"
import { Icon } from "antd";
import { withTheme } from "styled-components";

const Icons = {
	Check: withTheme(({ theme, ...props }) => <Icon type="check-circle" theme="twoTone" twoToneColor={theme.colors.green} {...props} />),
	Cross: withTheme(({ theme, ...props }) => <Icon type="close" {...props} />),
	Loading: withTheme(({ theme, ...props }) => <Icon type="loading" {...props} />),
	Scrimba: withTheme(({ theme, ...props }) => <Icon type="code" theme="twoTone" twoToneColor={theme.colors.blue} {...props} />),
	Video: withTheme(({ theme, ...props }) => <Icon type="video-camera" theme="twoTone" twoToneColor={theme.colors.blue} {...props} />),
	Image: withTheme(({ theme, ...props }) => <Icon type="file-image" theme="twoTone" twoToneColor={theme.colors.blue} {...props} />),
	Text: withTheme(({ theme, ...props }) => <Icon type="file-text" theme="twoTone" twoToneColor={theme.colors.blue} {...props} />),
	Left: withTheme(({ theme, ...props }) => <Icon type="left" theme="twoTone" {...props} />),
	Search: withTheme(({ theme, ...props }) => <Icon type="search"  {...props} />),
	ShoppingCart: withTheme(({ theme, ...props }) => <Icon type="shopping-cart" {...props} />),
	Menu: withTheme(({ theme, ...props }) => <Icon type="menu" {...props} />),
	Plus:withTheme(({ theme, ...props }) => <Icon type="plus" {...props} />),
	User:withTheme(({ theme, ...props }) => <Icon type="user" {...props} />),
}

export default Icons;
import {useDispatch, useSelector} from "react-redux";
import useWindowSize from "../../../common/hooks/useWindowSize/useWindowSize";
import {Avatar, Dropdown, Menu} from "antd";
import Oval from "../../../common/components/Oval/Oval";
import ResponsiveUtils from "../../../common/components/Responsivness/Responsivness";
import Text from "../../../common/components/Text/Text";
import React from "react";
import {Link} from "react-router-dom";
import Icons from "../Icons/Icons";


function AbstractUserButton({getPages}) {
	const user = useSelector(({auth}) => auth.user)
	const dispatch = useDispatch();

	const pages = getPages({user});

	useWindowSize();

	const UserButtonMenu = () => {
		return (
			<Menu style={{
				border: "solid 1px #EEE",
				borderRadius: 4,
				width: 200,
			}}>
				{
					pages.map(({disabled, to, title, onClick}, index) => (
						<Menu.Item key={index} disabled={disabled}>
							<Link to={to || ""} onClick={() => onClick && onClick(dispatch)}>
								<Text small strong>{title} {disabled && "(Coming soon)"}</Text>
							</Link>
						</Menu.Item>
					))
				}
			</Menu>
		)
	}

	return (
		<Dropdown overlay={<UserButtonMenu/>} placement="bottomRight">
			<Oval style={{margin: 0, whiteSpace: "nowrap"}}>
				{
					user
					&& ResponsiveUtils.choose({mobile: false, other: true})
					&& <Text inline small style={{margin: "0 15px 0 15px", cursor: 'pointer'}}>
						{user.firstName ? user.firstName : "Profile"}
					</Text>
				}
				<Avatar
					style={{
						background: "none",
						textTransform: 'uppercase',
						cursor: 'pointer'
					}}
					src={user && user.picture}>
					{
						user
							? user.firstName && user.firstName[0] && user.firstName[0].toUpperCase()
							: <Icons.User style={{color: "#555"}}/>
					}
				</Avatar>
			</Oval>
		</Dropdown>
	)
}

export default AbstractUserButton
import useFetcher from "./useFetcher"
import { useState } from "react";

export default function useCollections() {
	const [fetcher, loading, error, setError] = useFetcher();
	const [collections, setCollections] = useState([]);

	function fetchCollections() {
		return fetcher("/api/collection/collections").then(data => {
			setCollections(data);
		}).catch(e => setError(e))
	}

	return {
		collections,
		fetchCollections,
		collectionLoading: loading,
		collectionError: error,
	}
}

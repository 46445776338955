import React, { useState } from 'react'
import jsonFetcher from '../lib/jsonFetcher/jsonFetcher';

function useFetcher(fetchFn = jsonFetcher) {
	const [error, setError] = useState("")
	const [isLoading, setIsLoading] = useState();

	//const [success, setSuccess] = useState();

	return [
		(...params) => {
			setError("")
			//setSuccess("");
			setIsLoading(true)
			return fetchFn(...params)
				.finally(() => {
					setIsLoading(false);
				})
		},
		isLoading,
		error,
		(e) => {
			setError(e)
		}
	]
}

export default useFetcher

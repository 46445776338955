import React from "react"
import Logo from "../Logo/Logo"
import Text from "../../../common/components/Text/Text"
import Flex from "../../../common/components/Flex/Flex"
import Wrapper from "../Wrapper/Wrapper"

function WrapperWithLogo({
	title,
	children
}) {

	return (
		<Wrapper>
			<Flex style={{ minHeight: "100%" }}>
				<Logo width={180}
					style={{
						margin: "0 auto"
					}} />
				<Text
					grayed small
					style={{
						margin: 15
					}}>
					{title}
				</Text>
				{children}
			</Flex>
		</Wrapper>
	)
}

export default WrapperWithLogo


export default class ResponsiveUtils {
    static choose = (options) => {
        if (typeof options.mobile !== "undefined" && window.innerWidth <= 767) {
            return options.mobile;
        }
        if (typeof options.tablet !== "undefined" && window.innerWidth <= 991) {
            return options.tablet;
        }
        if (typeof options.computer !== "undefined" && window.innerWidth >= 992 &&
            window.innerWidth < 1200) {
            return options.computer;
        }
        if (typeof options.large !== "undefined" && window.innerWidth <= 1919) {
            return options.large;
        }
        if (typeof options.widescreen !== "undefined" && window.innerWidth >= 1920) {
            return options.widescreen;
        }
        return options.other;
    }
}
import React from 'react'

import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import { addLocaleData, IntlProvider } from 'react-intl';
import { MESSAGES } from './messages';
import useLanguage from '../../common/hooks/useLanguage/useLanguage';

addLocaleData([...en, ...fr]);

export const APP_LANGUAGES = ["english", "french"]
export const APP_LANGUAGES_ABRV = { english: "en", french: "fr" }

export const APP_CURRENCIES = ["tunisianDinar", "euro", "dollar"]
export const APP_CURRENCIES_SYMBOLS = { tunisianDinar: "DT", euro: "€", dollar: "$" }

function getMessages(language) {
	return Object.entries(MESSAGES).reduce((res, [key, next]) => {
		res[key] = next[language]
		return res
	}, {})
}

function MyIntlProvider(props) {
	const [locale, short] = useLanguage()

	console.log(locale, short)

	return (
		<IntlProvider {...props}
			locale={locale}
			messages={getMessages(short)} />
	)
}

export default MyIntlProvider

export const LANGUAGES = {
	"fr": "Français",
	"en": "English"
}

export const MESSAGES = {
	"comingSoon": {
		en: "Coming soon",
		fr: "Bientôt",
	},
	"subscribeNow": {
		en: "Subscribe now",
		fr: "Abonnez vous",
	},
	"allRightsAreReserved": {
		en: "All rights reserved",
		fr: "Tous droits réservés"
	},
	"enterYourEmailAddressHere": {
		en: "Enter your email address here",
		fr: "Entrer votre adresse email ici"
	},
	"notifyMe": {
		en: "Notify me",
		fr: "Me notifier"
	},
	"joinOurNewsletter": {
		en: "Join our newsletter",
		fr: "Inscrivez-vous à notre newsletter"
	},
	"invalidEmail": {
		en: "Your email is invalid",
		fr: "Votre email est invalide"
	},
	"emailRequired": {
		en: "Email is required",
		fr: "L'e-mail est obligatoire"
	},
	"passwordRequired": {
		en: "Password is required",
		fr: "Le mot de passe est obligatoire"
	},
	"userAlreadyExists": {
		en: "User already exists",
		fr: "Cet utilisateur existe déja",
	},
	"userDoesntExist": {
		en: "User doesn't exist, please try another email",
		fr: "Cet utilisateur n'existe pas, veuillez reessayer avec un autre e-mail",
	},
	"wrongEmailOrPassword": {
		en: "Wrong email and password. Please verify your credentials",
		fr: "E-mail ou mot de passe incorrect. Veuillez vérifier vos informations"
	},
	"accountIsNotConfirmedPleaseConfirmYourAccount": {
		en: "Your account is not confirmed. Please confirm your account through your email.",
		fr: "Vous n'avez pas encore confirmé votre inscription. Veuillez le confirmer par l'email que vous avez reçu."
	},
	"yourEmailIsAlreadySubscribed": {
		en: "Your email is already subscribed",
		fr: "Votre e-mail est déja abonné"
	},
	"youWillReceiveOurLatestNewsInYourEmailInbox": {
		en: "You will receive an email to confirm your subscription",
		fr: "Vous allez recevoir un e-mail pour confirmer votre abonnement"
	},
	"successfullySubscribedToOurNewsletter": {
		en: "Successfully subscribed to our newsletter",
		fr: "L'abonnement a été effectué avec succès"
	},
	"confirmationRejected": {
		en: "Account confirmation is rejected",
		fr: "Votre confirmation de compte est rejeté"
	},
	"confirmationRejectedExpired": {
		en: "Account confirmation is rejected because the token is expired",
		fr: "Votre confirmation de compte est rejeté car la clé a expirée"
	},
	"confirmationRejectedNotFromEmail": {
		en: "Account confirmation is rejected because the token is incorrect",
		fr: "Votre confirmation de compte est rejeté car la clé est invalide"
	},
	"confirmationWithSuccess": {
		en: "You have confirmed your account",
		fr: "La confirmation de votre compte a été effectué avec succés"
	},
	"subscriptionRejected": {
		en: "Your subscription is rejected",
		fr: "Votre abonnement est rejeté"
	},
	"subscriptionWithSuccess": {
		en: "You have confirmed your subscription",
		fr: "Votre abonnement a été effectué avec succés"
	},
	"email": {
		en: "Email",
		fr: "E-mail"
	},
	"password": {
		en: "Password",
		fr: "Mot de passe"
	},
	"home": {
		en: "Home",
		fr: "Accueil"
	},
	"login": {
		en: "Login",
		fr: "Connexion"
	},
	"register": {
		en: "Register",
		fr: "Inscription"
	},
	"gender": {
		en: "Gender",
		fr: "Sexe"
	},
	"name": {
		en: "Name",
		fr: "Prénom & Nom"
	},
	"id": {
		en: "ID",
		fr: "ID"
	},
	"yourSize":{
		en:"Your size",
		fr:"Votre taille"
	},
	"welcomeBackPleaseSigninBelow": {
		en: "Welcome back, please sign in below",
		fr: "Bienvenue, veuillez se connecter ci-dessous",
	},
	"somethingWentWrongPleaseTryAgainLater": {
		en: "Something went wrong, please try again later.",
		fr: "Il y a eu un problème, réessayez ultérieurement.",
	},
	"notLoggedIn": {
		en: "You are not currently logged in",
		fr: "Vous n'êtes pas connecté"
	},
	"youLoggedOutSuccessfully": {
		en: "You logged out successfully",
		fr: "Vous avez été déconnecté avec succès"
	},
	"oneMomentPlease": {
		en: "One moment, please",
		fr: "Une seconde, s'il vous plaît"
	},
	"unsubscriptionRejected": {
		en: "The unsubscription rejected, please try again later.",
		fr: "La demande d'annulation de votre abonnement est rejeté, réessayez ultérieurement."
	},
	"unsubscriptionFailed": {
		en: "The unsubscription failed, please try again later.",
		fr: "La demande d'annulation de votre abonnement a échoué, réessayez ultérieurement."
	},
	"unsubscriptionWithSuccess": {
		en: "Successfully unsubscribed from the newsletter",
		fr: "Vous avez été désabonné avec succès",
	},
	"male": {
		en: "Male",
		fr: "Homme"
	},
	"female": {
		en: "Female",
		fr: "Femme"
	},
	"other": {
		en: "Other",
		fr: "Autre"
	},
	"yourGender": {
		en: "Your gender",
		fr: "Votre sexe"
	},
	"yourFirstName": {
		en: "Your first name",
		fr: "Votre Prénom"
	},
	"yourLastName": {
		en: "Your last name",
		fr: "Votre nom"
	},
	"forgotYourPassword": {
		en: "Forgot your password",
		fr: "Mot de passe oublié"
	},
	"youWillReceiveAnEmailToResetYourPassword": {
		en: "You will receive an email to reset your password",
		fr: "Vous allez recevoir un e-mail pour réintialiser votre mot de passe"
	},
	"enterYourEmailUsedToCreateYourAccount": {
		en: "Enter the email address used to create your account",
		fr: "Entrez l'adresse email utilisée pour créer votre compte"
	},
	"sendMeAnEmail": {
		en: "Send me an email",
		fr: "Envoyer un email"
	},
	"back": {
		en: "Back",
		fr: "Retour"
	},
	"resendAnEmail": {
		en: "Resend an email",
		fr: "Réenvoyer un e-mail"
	},
	"emailWasSent": {
		en: "Email was sent",
		fr: "L'e-mail a été envoyé"
	},
	"searchAnything": {
		en: "Search anything...",
		fr: "Rechercher..."
	},
	"search": {
		en: "Search",
		fr: "Rechercher"
	},
	"overview": {
		en: "Overview",
		fr: "Accueil"
	},
	"products": {
		en: "Products",
		fr: "Produits"
	},
	"clients": {
		en: "Clients",
		fr: "Clients"
	},
	"subscribers": {
		en: "Subscribers",
		fr: "Abonnés"
	},
	"unsubscribers": {
		en: "Unsubscribers",
		fr: "Désabonnés"
	},
	"customize": {
		en: "Customize",
		fr: "Personnaliser"
	},
	"copied": {
		en: "Copied",
		fr: "Copié"
	},
	"copy": {
		en: "Copy",
		fr: "Copier"
	},
	"clear": {
		en: "Clear",
		fr: "Effacer"
	},
	"coverPhrase": {
		en: "It's not just a different skirt",
		fr: "Ce n'est pas juste une différente jupe"
	},
	"ourStory": {
		en: "Our story",
		fr: "Notre histoire"
	},
	"ourStoryDescription": {
		en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet nisl suscipit adipiscing bibendum est ultricies. Tincidunt ornare massa eget egestas purus viverra. Enim facilisis gravida neque convallis a cras semper auctor. Arcu dictum varius duis at consectetur lorem donec massa sapien. Neque sodales ut etiam sit amet. Scelerisque viverra mauris in aliquam sem. Consectetur libero id faucibus nisl tincidunt eget nullam non. Sit amet est placerat in egestas. Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Nulla aliquet porttitor lacus luctus accumsan tortor posuere ac ut. Purus gravida quis blandit turpis cursus in hac habitasse platea. Id velit ut tortor pretium viverra. Adipiscing elit ut aliquam purus sit amet. Sit amet aliquam id diam. Leo vel orci porta non. Posuere urna nec tincidunt praesent semper feugiat nibh. Scelerisque in dictum non consectetur. In iaculis nunc sed augue lacus viverra. Amet cursus sit amet dictum sit.",
		fr: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet nisl suscipit adipiscing bibendum est ultricies. Tincidunt ornare massa eget egestas purus viverra. Enim facilisis gravida neque convallis a cras semper auctor. Arcu dictum varius duis at consectetur lorem donec massa sapien. Neque sodales ut etiam sit amet. Scelerisque viverra mauris in aliquam sem. Consectetur libero id faucibus nisl tincidunt eget nullam non. Sit amet est placerat in egestas. Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Nulla aliquet porttitor lacus luctus accumsan tortor posuere ac ut. Purus gravida quis blandit turpis cursus in hac habitasse platea. Id velit ut tortor pretium viverra. Adipiscing elit ut aliquam purus sit amet. Sit amet aliquam id diam. Leo vel orci porta non. Posuere urna nec tincidunt praesent semper feugiat nibh. Scelerisque in dictum non consectetur. In iaculis nunc sed augue lacus viverra. Amet cursus sit amet dictum sit."
	},
	"shopNow": {
		en: "Shop now",
		fr: "Acheter"
	},
	"lookbook": {
		en: "Lookbook",
		fr: "Lookbook"
	},
	"contactUs": {
		en: "Contact us",
		fr: "Contact"
	},
	"newPassword": {
		en: "New password",
		fr: "Nouveau mot de passe"
	},
	"confirmNewPassword": {
		en: "Confirm your new password",
		fr: "Confirmer votre nouveau mot de passe"
	},
	"pleaseFillInYourNewPasswordBelowToResetYourAccountPassword": {
		en: "Please fill in your new password below to reset your account's password",
		fr: "Veuillez remplir votre nouveau mot de passe ci-dessous pour réinitialiser votre mot de passe"
	},
	"yourPasswordDoesntMatchWithTheConfirmation": {
		en: "Your password doesn't match with the one you confirmed",
		fr: "Votre mot de passe n'est pas conforme avec celui confirmé"
	},
	"yourPasswordHasBeenResetYouMayLoginNow": {
		en: "Your password has been reset, you may login now",
		fr: "Votre mot de passe a été réinitialisé, Vous pouvez vous connecter maintenant"
	},
	"missingCollectionFilter": {
		en: "Missing collection filter to find the collection",
		fr: "Vous n'avez pas préciser un filtre pour trouver la collection"
	},
	"noCollectionIdSpecified": {
		en: "No collection Specified",
		fr: "Aucune collection specifiée"
	},
	"totalNumberOfSubscribers": {
		en: "Total number of subscribers",
		fr: "Nombre total des abonnés"
	},
	"totalNumberOfUnSubscribers": {
		en: "Total number of unsubscribers",
		fr: "Nombre total de désabonnés"
	},
	"totalNumberOfSubscribersThisWeek": {
		en: "Total number of subscribers this week",
		fr: "Nombre total des abonnés cette semaine"
	},
	"totalNumberOfUnSubscribersThisWeek": {
		en: "Total number of unsubscribers this week",
		fr: "Nombre total de désabonnés cette semaine"
	},
	"thisWeek": {
		en: "This week",
		fr: "Cette semaine"
	},
	"all": {
		en: "All",
		fr: "Tous"
	},
	"addAProduct": {
		en: "Add a product",
		fr: "Ajouter un produit"
	},
	"everyProductCreatedWillBePrivate": {
		en: "Every product created will be private until you publish it",
		fr: "Chaque produit créé sera privé jusqu'à ce que vous le publiiez"
	},
	"title": {
		en: "Title",
		fr: "Titre"
	},
	"description": {
		en: "Description",
		fr: "Description"
	},
	"price": {
		en: "Price",
		fr: "Prix"
	},
	"colors": {
		en: "Colors",
		fr: "Couleurs"
	},
	"pictures": {
		en: "Pictures",
		fr: "Photos"
	},
	"collection": {
		en: "Collection",
		fr: "Collection"
	},
	"public": {
		en: "Public",
		fr: "Public"
	},
	"inEnglish": {
		en: "in english",
		fr: "en anglais"
	},
	"inFrench": {
		en: "in french",
		fr: "en français"
	},
	"english": {
		en: "English",
		fr: "Anglais"
	},
	"french": {
		en: "French",
		fr: "Français"
	},
	"addProduct": {
		en: "Add product",
		fr: "Ajouter produit"
	},
	"inDollar": {
		en: "in Dollar",
		fr: "en Dollar"
	},
	"inTunisianDinar": {
		en: "in Tunisian Dinar",
		fr: "en Dinar Tunisien"
	},
	"inEuro": {
		en: "in Euro",
		fr: "en Euro"
	},
	"typeAColor": {
		en: "Type a color",
		fr: "Saisir une couleur"
	},
	"addAColor": {
		en: "Add a color",
		fr: "Ajouter une couleur"
	},
	"photo": {
		en: "Photo",
		fr: "Photo"
	},
	"chooseYourOwnStyle":{
		en:"Choose your own style",
		fr:"Choisissez votre style"
	}
};


import React from 'react'
import Alert from '../../../common/components/Alert/Alert';
import {FormattedMessage} from 'react-intl';

function FormError({errors, extra}) {
	return (
		errors
			? <Alert type="error" showIcon message={
				<>
					{
						(typeof errors === "string")
							? <FormattedMessage id={errors}/>
							: Object.values(errors).map((v, index) => <FormattedMessage key={index} id={v.message}/>)
					}
					{" "}
					{extra}
				</>
			}/>
			:null
	)
}

export default FormError

import React, { useContext } from "react"
import { Input } from "antd";
import { FormContext } from "../../../common/components/Form/Form";

// InputBox is a component that:
// - provides onValueChange 
// - push the value in the FormContext
function InputBox({
	Component = Input,
	onValueChange,
	onChange,
	style,
	noPropagation = false,
	getFromEvent = e => e.target.value,
	...props }) {
	const { handleChange, disabled } = useContext(FormContext)

	return <Component
		disabled={disabled}
		{...props}
		onChange={(e) => {
			onChange && onChange(e);
			onValueChange && onValueChange(e.target ? getFromEvent(e) : e);
			handleChange && !noPropagation && handleChange(props.name, e.target ? getFromEvent(e) : e);
		}}
		style={{
			marginBottom: 15,
			...style,
		}} />
}


export default InputBox
import {loadingActions, mainActions, notificationActions} from "./actions";

export default function mainReducer(state = {
	language: localStorage.getItem("language")
}, action) {
	switch (action.type) {
		case mainActions.toggleMenu:
			return {
				...state,
				isMenuOpen: action.payload || !state.isMenuOpen
			}

		case loadingActions.start:
		case loadingActions.stop:
			return {
				...state,
				isLoading: action.type === loadingActions.start
			}
		case notificationActions.topMessage:
			//message[action.status](action.message, 2)
			return {
				...state,
				topMessage: {
					message: action.message,
					status: action.status
				}
			}

		case mainActions.changeLanguage:
			localStorage.setItem("language", action.payload)
			return {
				...state,
				language: action.payload
			}

		default:
			return state;
	}
}
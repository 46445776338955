import React, { createContext, useState, useEffect } from "react";

export const FormContext = createContext({ createdAt: new Date() })

export default function Form({
	children,
	onChange,
	disabled,
	onSubmit,
	style,
	setLoading = () => { } }) {
	const [form, setForm] = useState({});


	const handleChange = (key, value) => {
		setForm({
			...form,
			[key]: value
		})
	}
	function handleSubmit(e) {
		e.preventDefault();
		setLoading && setLoading(true)
		Promise.resolve(onSubmit(form)).then(() => {
			setLoading && setLoading(false)
		})
	}

	useEffect(() => {
		onChange && onChange(form)
	}, [form])

	return (
		<FormContext.Provider value={{ form, handleChange, disabled }}>
			<form style={style} onSubmit={handleSubmit}>
				{children}
			</form>
		</FormContext.Provider>
	)

}
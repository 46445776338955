import React from 'react'
import Wrapper from '../components/Wrapper/Wrapper';
import Logo from '../components/Logo/Logo';
import Flex from '../../common/components/Flex/Flex';
import useWindowSize from '../../common/hooks/useWindowSize/useWindowSize';
import FadeSlideShow from '../../common/components/FadeSlideShow/FadeSlideShow';
import Title from '../../common/components/Title/Title';
import { FormattedMessage, injectIntl } from "react-intl"
import EmailSubscription from '../components/EmailSubscription/EmailSubscription';
import Footer from '../components/Footer/Footer';


function WelcomePage({ intl }) {
	//eslint-disable-next-line
	const size = useWindowSize()

	return (
		<>
			<Wrapper>
				<FadeSlideShow
					style={{
						position: 'absolute',
						zIndex: -1
					}}
					photos={Array(13).fill(1).map((_, index) => `/assets/testing/photos/${index + 1}.${index + 1 < 3 ? "PNG" : "jpg"}`)} />
				<Flex style={{ height: "100%" }}>
					<div style={{
						padding: 20,
						textAlign: "center",
						backgroundColor: "white"
					}}>
						<Logo width={180} />
						<Title
							wide
							size={10}
							style={{ opacity: 1 }}
							uppercase sub>
							<FormattedMessage id="comingSoon" />
						</Title>
					</div>
				</Flex>
			</Wrapper>
			<Flex>
				<EmailSubscription />
			</Flex>
		</>
	)
}

export default injectIntl(WelcomePage)

import React from 'react';
import Wrapper from "./Wrapper";

const withWrapper = (MyComponent)=>(props)=>{
	return (
		<Wrapper rowStyle={{
			overflow: "hidden" // Otherwise the browser leaves a space on the right side
		}}>
			<MyComponent {...props}/>
		</Wrapper>
	);
}

export default withWrapper;

import React, { useState } from 'react'
import InputBox from '../Input/Input';
import Button from '../Button/Button';
import useSubscribe from '../../hooks/useSubscribe';
import { FormattedMessage, injectIntl } from 'react-intl';
import Title from '../../../common/components/Title/Title';
import { Result, Select } from 'antd';
import Alert from '../../../common/components/Alert/Alert';
import Form from '../../../common/components/Form/Form';
import Flex from '../../../common/components/Flex/Flex';
import { ColWrapper } from '../Wrapper/Wrapper';

function EmailSubscription({ intl }) {
	const [detailed, setDetailed] = useState()
	const [subscribe, success, errors, isLoading] = useSubscribe()
	return (
		<div style={{ width: "100%" }}>
			{success
				? <Result
					status="success"
					title={
						<Title sub>
							{intl.formatMessage({ id: "youWillReceiveOurLatestNewsInYourEmailInbox" })}
						</Title>
					} />
				: <Form
					disabled={isLoading}
					onSubmit={(data) => subscribe(data)}
					style={{
						textAlign: "center",
						margin: "100px auto",
						maxWidth: 450,
					}}>
					<Title bold sub style={{ opacity: 1 }}><FormattedMessage id="joinOurNewsletter" /></Title>
					<ColWrapper style={{
						float: "initial"
					}}>
						<Flex>
							<InputBox
								required
								name="email"
								onFocus={() => setDetailed(true)}
								placeholder={intl.formatMessage({ id: "enterYourEmailAddressHere" })} />
							{
								detailed
								&& (
									<>
										<Flex row justifyContent="space-between">
											<InputBox
												autocomplete="off"
												required
												name="firstName"
												style={{ width: "49%" }}
												placeholder={intl.formatMessage({ id: "yourFirstName" })} />
											<InputBox
												autocomplete="off"
												required
												name="lastName"
												style={{ width: "49%" }}
												placeholder={intl.formatMessage({ id: "yourLastName" })} />
										</Flex>
										<InputBox Component={Select}
											name="gender"
											placeholder={intl.formatMessage({ id: "yourGender" })}
											style={{
												width: 150
											}}>
											<Select.Option value="male"><FormattedMessage id="male" /></Select.Option>
											<Select.Option value="female"><FormattedMessage id="female" /></Select.Option>
											<Select.Option value="other"><FormattedMessage id="other" /></Select.Option>
										</InputBox>
									</>
								)
							}
						</Flex>
					</ColWrapper>
					{
						errors
						&& <Alert type="error" showIcon message={
							Object.values(errors).map((v, index) => <FormattedMessage key={index} id={v.message} />)
						} />
					}
					<Button
						loading={isLoading}
						htmlType="submit"
						type="primary">
						<FormattedMessage id="notifyMe" />
					</Button>
				</Form>
			}
		</div>
	)
}

export default injectIntl(EmailSubscription)

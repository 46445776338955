export default {
	colors: {
		primary: "black",
		background: "white",
		green: "#52c41a",
		red: "#eb2f96",
		blue: "#1890ff",
		lightBlue: "#e6f7ff",
		grey:"#666",
		white: "white"
	}
}
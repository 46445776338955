import React, { useEffect, useState } from 'react'
import Alert from '../Alert/Alert';

function DisconnectionAlert({ style, ...props }) {
	const [error, setError] = useState(false)

	useEffect(() => {
		window.addEventListener("offline", () => {
			setError(true)
		})

		window.addEventListener("online", () => {
			setError(false)
		})
	}, [])


	return (
        <>
            {error && <Alert
            	message="Disconnected"
            	description="You seem to be offline, please wait few moments..."
            	banner
            />}
            <div {...props} style={{
            	opacity: error ? 0.5 : 1,
            	pointerEvents: error ? "none" : "all",
            	...style
            }} />
        </>
	)
}

export default DisconnectionAlert

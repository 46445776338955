import React from "react"
import PAGES from "./PAGES";
import {Link, withRouter} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import comparePath from "../../../common/lib/comparePath/comparePath";

function AbstractMenu({
						  Component = ({children}) => children,
						  location
					  }) {

	return (
		<>
			{
				Object.entries(PAGES).map(([path, page], index, array) => (
					<Link to={"/home/" + page}
						  key={index}>
						<Component index={index}
								   active={comparePath(location.pathname, "/home/" + page)} // Resolve of the /
								   length={array.length}>
							<FormattedMessage id={path}/>
						</Component>
					</Link>
				))
			}
		</>
	)

}

export default withRouter(AbstractMenu);
import React from 'react';
import { BrowserRouter } from "react-router-dom"
import Routes from './specific/routes';
import { Provider } from "react-redux"
import store from './specific/Store/Store';
import { ThemeProvider } from 'styled-components';
import globalTheme from './specific/config/globalTheme';
import MyIntlProvider from './specific/Intl/MyIntlProvider';

function App() {

	return (
		<Provider store={store}>
			<ThemeProvider theme={globalTheme}>
				<MyIntlProvider>
					<BrowserRouter>
						<Routes />
					</BrowserRouter>
				</MyIntlProvider>
			</ThemeProvider>
		</Provider>
	);
}

export default App;

import { createPromiseActions } from "../Store/createPromiseActions";
import { fetchAction } from "../Store/common/fetchSaga"


export const loginActions = createPromiseActions({
	login: "LOGIN_FETCH",
	check: "LOGIN_CHECK",
	logout: "LOGOUT_FETCH"
})

export const login = (data) => fetchAction(
	loginActions.login,
	"/api/auth/login",
	{
		method: "post",
		body: data
	}
)

export const checkLogin = () => fetchAction(
	loginActions.check,
	"/api/auth/me"
)

export const logout = () => fetchAction(
	loginActions.logout,
	"/api/auth/logout"
)
import React from 'react';
import Title from "../../../common/components/Title/Title";
import Flex from "../../../common/components/Flex/Flex";
import styled from "styled-components";
import useWindowSize from "../../../common/hooks/useWindowSize/useWindowSize";
import ResponsiveUtils from "../../../common/components/Responsivness/Responsivness";


const StyledTitle= styled(Title)`
	transition: transform .3s, color .3s;
	*:hover>&{
		color:black!important;
		transform: translateX(20px);
	}

`

function CoverItemOverlay({title,photo, ...props}) {
	useWindowSize();

	return (
		<Flex row justifyContent="flex-start" alignItems="center" {...props}
			style={{
				width:"100%",
				marginBottom:15,
				...props.style
			}}
		>
			<div
				style={{
					minWidth:ResponsiveUtils.choose({mobile:"45%", other:"35%"}),
					height:"100%",
					background:photo && `url(${photo}) center center`,
					backgroundSize:"cover",
					backgroundColor:"#EEE",
				}}/>
			<StyledTitle size={ResponsiveUtils.choose({mobile:40, tablet:60, other:120})} style={{
				marginLeft:-20,
				fontWeight:500,
				color:"#DDD"
			}}>
				{title}
			</StyledTitle>
		</Flex>
	);
}

export default CoverItemOverlay;

import React from 'react'
import { LANGUAGES } from '../../Intl/messages';
import { Select } from 'antd';
import SelectBox from '../../../common/components/SelectBox/SelectBox';
import useLanguage from '../../../common/hooks/useLanguage/useLanguage';


function LanguageSelector() {
	//eslint-disable-next-line
    const [language, short, changeLanguage] = useLanguage()
	return (
		<SelectBox
			defaultValue={short}
			onChange={changeLanguage}
			size="small" style={{ width: 100 }}>
			{
				Object.entries(LANGUAGES).map(([key, title], index) => (
					<Select.Option key={index} value={key}>
						{title}
					</Select.Option>
				))
			}
		</SelectBox>
	)
}

export default LanguageSelector

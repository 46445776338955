import { combineReducers } from "redux";
import mainReducer from "./common/mainReducer";
import AuthReducer from "../Auth/AuthReducer";

const allReducers = combineReducers({
	auth: AuthReducer,
	main: mainReducer
})


export default allReducers
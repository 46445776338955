import React from 'react';
import {Route, Switch} from 'react-router-dom';
import OurStoryPage from './OurStoryPage/OurStoryPage';
import UserBar from '../components/NavBar/UserBar';
import MenuFullPage from "../components/Menu/MenuFullPage";
import {useSelector} from "react-redux";
import HomePageOverlay from "./HomePage/HomePage.Overlay";
import ShopNow from "./ShopNow/ShopNow";


function HomeRoutes({match}) {
	const isMenuOpen = useSelector(({main}) => main.isMenuOpen)

	return (
		<>
			<UserBar/>

			<Switch>
				{isMenuOpen && <MenuFullPage/>}
				<Route path={`${match.path}/our-story`} component={OurStoryPage}/>
				<Route path={`${match.path}/shop-now`} component={ShopNow}/>
				<Route path={`${match.path}/`} exact component={HomePageOverlay}/>
			</Switch>
		</>
	);
}

export default HomeRoutes;

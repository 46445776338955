import React from 'react'
import {logout} from '../../Auth/actions';
import AbstractUserButton from "./AbstractUserButton";
import {useSelector} from "react-redux";
import {FormattedMessage} from "react-intl"
import {withRouter} from "react-router";


function UserButton({history}) {
	const user = useSelector(({auth}) => auth.user)
	const guestPages = [
		{
			title:<FormattedMessage id="register" />,
			to:"/register",
			//onClick:()=>history.push("/register")
		},
		{
			title:<FormattedMessage id="login" />,
			to:"/login"
		}
	]
	const userPages = [
		{
			title: "Settings",
			disabled: true,
			to: '/home/profile',
		},
		{
			title: "Logout",
			onClick: (dispatch) => {
				dispatch(logout())
			},
		},
	];
	return (
		<AbstractUserButton getPages={({user}) => user ? userPages: guestPages}/>
	)
}


export default withRouter(UserButton)
